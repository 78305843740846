<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <Container ref="container">
            <List>
                <BestowItem
                    v-for="item in dataList"
                    :key="item.id"
                    :data="item"
                />
            </List>

            <infinite-loading
                ref="infinite"
                :disabled="infiniteDisabled"
                @infinite="onInfinite"
            />
        </Container>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import infiniteLoading from '@/mixin/infiniteLoading';
import BestowItem from '@/pages/distributor/percentage/_bestowItem';

export default {
    name: 'Withdraw',
    components: { BestowItem },
    mixins: [pageMixin, infiniteLoading],
    data() {
        return {
            dataList: [],
        };
    },
    methods: {
        getListData({ currentPage, pageSize } = {}) {
            return this.$api.Buyer.Mb.Distributor.distributorBestowList({
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
                pageSize,
            }).then(json => {
                const res = json.data;
                this.dataList = this.dataList.concat(res.data);
                return Promise.resolve(json);
            });
        },
    },
};
</script>

<style lang="scss">
</style>
