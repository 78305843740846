<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <container ref="container">
            <div class="goods-swiper">
                <ad-swiper
                    is-images-background
                    :data="imgList"
                />
                <!--<div class="sold-out"
                     v-if="goods.sellQuantity>=goods.limitQuantity"
                ></div>-->
            </div>

            <div class="goods-info">
                <div class="body">
                    <div class="label-wrap">
                        <div class="name">
                            {{ goods.name }}
                        </div>
                        <div
                            class="desc"
                            v-if="goods.desc"
                        >
                            {{ goods.desc }}
                        </div>
                    </div>
                    <div
                        class="share active"
                        v-if="!!shoeSize"
                    >
                        <i class="f-icon">&#xf073;</i>
                        <div class="label">
                            推荐 <span class="badge badge-main">{{ shoeSize }}</span> 码
                        </div>
                    </div>
                </div>
                <div class="footer">
                    <div class="price fs-big">
                        {{ goods.price }}
                        <span
                            v-if="goods.marketPrice"
                            class="old"
                        >{{ goods.marketPrice }}</span>
                    </div>
                    <div
                        v-if="goodsSales"
                        class="stock tc-g6"
                    >
                        销量：{{ goodsSales }}
                    </div>
                    <!--<div class="stock tc-g6">
                        库存：{{ goods.showStock }}
                    </div>-->
                </div>
            </div>

            <!-- 分享赚/团队提成 -->
            <template v-if="isDistributor && ((!!($globalVar.shopData.distributionIncomeMode&4) || goods.commisionPrice))">
                <!--<div class="pa-a">
                    <div
                        v-if="isDistributor && !!($globalVar.shopData.distributionIncomeMode&4)"
                        class="share-earn"
                    >
                        <div class="inner">
                            &lt;!&ndash;                            <div>团队收益:&nbsp;</div>&ndash;&gt;
                            <div class="commission-list">
                                <div
                                    v-for="(item,index) in goods.eachLevelPriceList"
                                    :key="index"
                                    class="dp-b"
                                >
                                    {{ item.levelName }}:
                                    <span class="price">{{ item.levelIncomePrice }}</span>
                                </div>
                                <Linker
                                    v-if="!goods.eachLevelPriceList || !goods.eachLevelPriceList.length"
                                    to="/distributor/cardList"
                                >
                                    升级权益赚取佣金
                                </Linker>
                            </div>
                        </div>
                        <div class="pa-a">
                            <div
                                class="btn btn-red big radius"
                                @click="onShareEarn"
                            >
                                分享素材
                            </div>
                        </div>
                    </div>
                </div>-->
                <!--<list>
                    <list-item
                        icon="&#xf021;"
                        icon-class-name="tc-blue"
                        label="分享素材"
                        class="link"
                        @click="onShareEarn"
                    />
                </list>-->
                <div
                    ref="copyRecommend"
                    v-if="goods.commisionPrice"
                    class="share-income"
                    :data-clipboard-text="sharedData.link"
                    @click="onShareBtn"
                >
                    <div class="inner">
                        <div class="body">
                            <div class="info">
                                <div class="label">
                                    预计收益
                                </div>
                                <div class="value">
                                    <span class="price">{{ goods.commisionPrice }}</span>
                                    <span>收益比例: {{ goods.commisionRate }}%</span>
                                </div>
                            </div>
                            <div class="share-btn">
                                立即<br>分享
                            </div>
                        </div>
                        <div class="footer">
                            分享好友下单，享受返现
                        </div>
                    </div>
                </div>
            </template>

            <tabs class="average">
                <tab-panel label="商品详情">
                    <div
                        class="pa-a bc-w"
                        style="line-height: 0;"
                    >
                        <img
                            v-for="(item,index) in goods.detailImageList"
                            :key="index"
                            :src="item"
                            width="100%"
                        >
                    </div>
                </tab-panel>
            </tabs>
        </container>
        <div class="footer">
            <!--<div
                class="btn btn-main"
                @click="onShare"
            >分享赚佣金
            </div>-->
            <Linker
                :to="`/goods/detail/${goods.id}`"
                class="btn btn-main"
            >
                立即购买
            </Linker>
        </div>


        <!-- popup -->
        <shares-box
            :commision-price="(goods.eachLevelPriceList && goods.eachLevelPriceList.length)?goods.eachLevelPriceList[0].levelIncomePrice:''"
            :share-link="sharedData.link"
            ref="sharesBox"
            @onItem="shareOnItem"
        />
        <!--微信分享-->
        <transition name="bounce">
            <div
                class="share-popup"
                v-show="sharePopupShow"
                @click="sharePopupShow=false"
                :style="`background: rgba(0, 0, 0, .8) url('${shareImg}') top center no-repeat; background-size:100% auto;`"
            />
        </transition>
        <!--二维码弹框-->
        <transition name="bounce">
            <div
                class="share-popup"
                v-show="QRCodePopupShow"
                @click.self="QRCodePopupShow=false"
            >
                <img
                    ref="QRCode"
                    :src="QRCodeSrc"
                    width="60%"
                    style="border-radius: .15rem;"
                >
                <div class="tc-w pa-l pa-r ma-t ta-c fs-big">
                    长按保存图片<br>分享此商品的二维码<br>顾客可通过识别此二维码<br>查看商品详情
                </div>
            </div>
        </transition>
        <!--图文二维码弹框-->
        <transition name="bounce">
            <div
                class="share-popup"
                v-show="graphicPopupShow"
                @click.self="graphicPopupShow=false"
            >
                <img
                    ref="graphicImg"
                    :src="graphicSrc"
                    width="80%"
                    style="border-radius: .15rem;"
                >
                <canvas
                    class="canvas"
                    v-show="false"
                    ref="graphicCanvas"
                    width="1000"
                    height="1400"
                />
                <div class="tc-w ma-t ta-c fs-big">
                    长按保存图片
                </div>
            </div>
        </transition>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import { Buyer } from '@/api';
import sharesBox from './_sharesBox';
import QRCode from 'qrcode';
// import logo from '@/assets/images/comm/logo.png';
import shareImg from '@/assets/images/share/share-earn.png';
import Clipboard from 'clipboard';

export default {
    name: 'GoodsDetail',
    mixins: [pageMixin],
    components: { sharesBox },
    data() {
        return {
            goods: {},
            sharedData: {},
            shareImg,
            sharePopupShow: false,
            QRCodePopupShow: false,
            graphicPopupShow: false,
            QRCodeSrc: '',
            graphicSrc: '',

            imgLoaded: false,
        };
    },
    beforeRouteEnter(to, from, next) {
        const id = to.params.id;
        Buyer.Mb.Distributor.distributorGoodsDetail({ id })
            .then(json => {
                const res = json.data.data;
                const { goods, buyerDeliveryAddress: address } = res;
                next(vm => {
                    vm.goods = goods;
                    vm.address = address || {};
                    vm.setShareData({
                        title: goods.goodsName,
                        desc: goods.goodsSubtitle,
                        imgUrl: goods.mainImageUrl,
                        link: `/goods/detail/${id}`,
                        successData: {
                            urllinkType: 1,
                            objectId: goods.id,
                        },
                    }).then(data => {
                        vm.sharedData = data;
                        console.log('111111111', { ...data });
                    });
                });
            })
            .catch(() => {
                next();
            });
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
        imgList() {
            if (!this.goods.imgList || !this.goods.imgList.length) return [];
            return this.goods.imgList.map(item => {
                return {
                    imgUrl: item,
                };
            });
        },
        $$sharesBox() {
            return this.$refs.sharesBox;
        },
        $$graphicCanvas() {
            return this.$refs.graphicCanvas;
        },
        ctx() {
            return this.$$graphicCanvas.getContext('2d');
        },
        // 销量
        goodsSales() {
            return this.goods.hasOwnProperty('goodsSales') ? this.goods.goodsSales + '' : '';
        },
    },
    methods: {
        onShareEarn() {
            this.$wx.shareLater({
                ...this.sharedData.successData,
                shareUuid: this.sharedData.uuid,
                urllink: this.sharedData.link,
                shareChannel: 3,
            });
            this.$router.push(`/distributor/goods/shareEarn/${this.id}?shareUuid=${this.sharedData.uuid}`);
        },
        onShare() {
            this.$$sharesBox.open();
        },

        drawText(t, x, y, w, h = 99999999, lh = 70) {
            const { ctx } = this;
            const chr = t.split('');
            let temp = '';
            const row = [];

            // ctx.font = '20px Arial';
            // ctx.fillStyle = 'black';
            // ctx.textBaseline = 'middle';

            for (let a = 0; a < chr.length; a++) {
                if (ctx.measureText(temp).width < w) {
                    // 保留
                } else {
                    if (row.length + 1 >= h) {
                        temp += '...';
                        break;
                    }
                    row.push(temp);
                    temp = '';
                }
                temp += chr[a];
            }

            row.push(temp);

            for (let b = 0; b < row.length; b++) {
                ctx.fillText(row[b], x, y + b * lh);
                if (b + 1 >= h) return;
            }
        },
        drawQRCode() {
            return new Promise((resolve, reject) => {
                console.log({ ...this.sharedData });
                QRCode.toCanvas(
                    this.sharedData.link,
                    {
                        margin: 3,
                        scale: 10,
                        errorCorrectionLevel: 'H',
                    },
                    (error, canvas) => {
                        if (error) {
                            reject(error);
                            return;
                        }

                        const ctx = canvas.getContext('2d');
                        this.$utils.Comm.loadImg(this.$globalVar.shopData.logoUrl).then(img => {
                            ctx.fillStyle = '#fff';
                            ctx.fillRect(canvas.width / 2 - 45, canvas.height / 2 - 45, 90, 90);
                            ctx.drawImage(img, canvas.width / 2 - 40, canvas.height / 2 - 40, 80, 80);
                            resolve(this.QRCodeSrc);
                            this.QRCodeSrc = canvas.toDataURL();
                        });
                    },
                );
            });
        },
        async drawGraphic() {
            if (this.graphicSrc) {
                return Promise.resolve();
            }
            this.$nprogress.start();
            const { ctx } = this;
            const cvs = ctx.canvas;
            const pa = 40;
            ctx.fillStyle = '#fff';
            ctx.fillRect(0, 0, cvs.width, cvs.height);
            await this.drawQRCode();
            await this.$utils.Comm.loadImgs([
                this.$globalVar.shopData.logoUrl,
                this.goods.mainImageUrl,
                this.QRCodeSrc,
                /* ...this.goodsSwiperData.filter((item, index) => {
                    return index < 2;
                }).map((item) => {
                    return (item.imgUrl += '@750w');
                }), */
            ]).then(imgs => {
                let imgH = 0;
                /* logo */
                ctx.drawImage(imgs[0], pa, 0, 160, 160);
                /* 大图 */
                imgH = cvs.width * 0.7;
                ctx.drawImage(imgs[1], (cvs.width - imgH) / 2, 160 + pa, imgH, imgH);
                /* 二维码 */
                const qrcodeWidth = 350;
                ctx.drawImage(
                    imgs[2],
                    cvs.width - 20 - qrcodeWidth,
                    cvs.height - pa * 2 - qrcodeWidth,
                    qrcodeWidth,
                    qrcodeWidth,
                );
                /* 小图 */
                if (imgs.length > 3) {
                    imgs.forEach((item, index) => {
                        if (index < 3) return;
                        const w = (cvs.width - 40) / 2 - 10;
                        const i = index - 3;
                        console.log(w, i);
                        ctx.drawImage(item, 20 + (w + 20) * i, cvs.width * 0.6 + 140, w, w);
                    });
                    /* ctx.drawImage(
                        imgs[3],
                        20,
                        cvs.width * 0.6 + 140,
                        (cvs.width - 40) / 2 - 10,
                        cvs.width / 2);
                    ctx.drawImage(
                        imgs[4],
                        cvs.width / 2 + 10,
                        cvs.width * 0.6 + 140,
                        (cvs.width - 40) / 2 - 10,
                        cvs.width / 2);
                    */
                }

                /* 其它 */
                ctx.font = '26px Helvetica';
                ctx.fillStyle = '#999';
                ctx.textAlign = 'center';
                ctx.fillText('长按识别二维码 查看详情', cvs.width - qrcodeWidth / 2 - 20, cvs.height - 20 - pa);
                // ctx.fillText('查看商品详情', cvs.width - qrcodeWidth / 2 - 20, cvs.height - pa * 2);
                ctx.font = '36px Helvetica';
                ctx.fillStyle = '#333';
                ctx.textAlign = 'left';
                this.drawText(this.goods.goodsName, pa, cvs.height - pa - 337, cvs.width - qrcodeWidth - pa * 3, 2, 60);
                ctx.font = '32px Helvetica';
                ctx.fillStyle = '#868e96';
                this.drawText(
                    this.goods.goodsSubtitle || '',
                    pa,
                    cvs.height - pa - 200,
                    cvs.width - qrcodeWidth - pa * 3,
                    2,
                    50,
                );
                ctx.font = '72px Helvetica';
                ctx.fillStyle = '#f60';
                ctx.fillText(
                    `${this.currency} ${this.isActivityGoods ? this.activityGoods.goodsPrice : this.goods.price}`,
                    pa,
                    cvs.height - pa - 18,
                );
            });
            ctx.save();
            this.graphicSrc = cvs.toDataURL();
            this.$nprogress.done();
        },

        shareOnItem(action) {
            switch (action) {
                case 'weixin':
                    this.sharePopupShow = true;
                    break;
                case 'QRCode':
                    this.drawGraphic().then(() => {
                        this.QRCodePopupShow = true;
                    });
                    break;
                case 'graphic':
                    this.drawGraphic().then(() => {
                        this.graphicPopupShow = true;
                    });
                    break;
            }

            if (action !== 'weixin') {
                this.$wx.shareLater({
                    ...this.sharedData.successData,
                    shareUuid: this.sharedData.uuid,
                    urllink: this.sharedData.link,
                    shareChannel: 3,
                });
            }
        },
        initClipboard() {
            const { copyRecommend } = this.$refs;
            if (!copyRecommend) {
                setTimeout(() => {
                    this.initClipboard();
                }, 500);
                return;
            }
            console.log(copyRecommend);
            const copySuccess = () => {
                this.$wx.shareLater({
                    ...this.sharedData.successData,
                    shareUuid: this.sharedData.uuid,
                    urllink: this.sharedData.link,
                    shareChannel: 3,
                });
                this.$messageBox.tips('分享链接复制成功', 3000);
            };
            const copyError = () => {
                this.$messageBox.tips('分享链接复制失败', 3000);
            };
            const $node = new Clipboard(copyRecommend.$el);
            $node.on('success', copySuccess);
            $node.on('error', copyError);
        },
        onShareBtn() {
            if (!this.$globalVar.device.isWeiXin) return;
            this.sharePopupShow = true;
        },
    },
    mounted() {
        !this.$globalVar.device.isWeiXin && this.initClipboard();
    },
};
</script>

<style lang="scss" scoped>
.footer {
    background-color: #fff;
    > .cart {
        color: $gray6;
        flex: 0 0 auto;
        width: auto;
    }
}

.share-earn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding 0;
    background-color: #ffeff0;
    border: 0.05rem solid $color-red;
    border-radius: 0.1rem;

    > .inner {
        flex: 1 1 auto;
        padding: 0 $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-right: 0.05rem dashed $color-red;
        font-weight: bold;
        &,
        .price {
            color: $color-red;
        }
        .commission-list {
            > .badge {
                + .badge {
                    margin-top: $margin-small;
                }
            }
        }
    }
    > .btn {
        margin: 0 $margin;
    }
}
.share-income {
    padding: $padding;
    > .inner {
        padding: $padding;
        background-color: #ffeff0;
        border-radius: 0.1rem;
        > .body {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-bottom: $padding-small;
            > .info {
                > .label {
                    color: $color-red;
                }
                > .value {
                    > .price {
                        color: $color-red;
                        font-size: 0.6rem;
                        font-weight: bold;
                        margin-right: $margin-small;
                    }
                }
            }
            > .share-btn {
                width: 1rem;
                height: 1rem;
                background-color: $color-red;
                color: #fff;
                border-radius: 100vw;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                line-height: 1;
            }
        }
        > .footer {
            background-color: transparent;
            padding-top: $padding-small;
            border-top: 1px dashed $color-red;
        }
    }
}
.share-popup {
    .canvas {
        max-width: 80vw;
        height: auto;
    }
}
</style>
<style lang="scss">
.goods-swiper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
    > .swiper-container {
        position: absolute;
        z-index: 100;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    > .sold-out {
        position: absolute;
        z-index: 200;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background: rgba(0, 0, 0, 0.5) url('~@/assets/images/comm/icon01.png') center no-repeat;
        background-size: 70%;
        width: 100%;
        height: 100%;
    }
}

.goods-info {
    // padding: $padding 0;
    background-color: #fff;
    > .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;

        > .price-wrap,
        > .countdown-wrap {
            min-height: 1rem;
            padding: 0 $padding;
        }
        > .price-wrap {
            flex: 0.54 0.54 1%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            background-color: $color-main;
            .price {
                color: #fff;
                line-height: 1.2;
            }
            > .price {
                font-size: 0.4rem;
            }
            > .old-price {
                color: #fff;
                font-size: 0.22rem;
                line-height: 1.2;
            }
        }
        > .countdown-wrap {
            flex: 0.46 0.46 1%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background-color: $color-yellow;
            > .countdown {
                justify-content: center;
                color: $color-red;
            }
        }
    }
    > .body {
        padding: $padding-small $padding;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        > .label-wrap {
            flex: 1 1 auto;
            > .name {
                font-size: 0.32rem;
            }
            > .desc {
                color: $gray6;
                line-height: 1.2;
                margin-top: $margin;
            }
        }
        > .share {
            flex: 0 0 auto;
            padding: $padding;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 0.22rem;
            color: $gray6;
            > .f-icon {
                font-size: 0.6rem;
                line-height: 1;
                margin-bottom: $margin-small;
            }
            > .label {
                white-space: nowrap;
            }

            &.active {
                color: $color-main;
            }
        }
    }
    > .footer {
        padding: $padding-small $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }
}
</style>
