<!-- Created by henian.xu on 2017/10/1. -->

<template>
    <Page>
        <div
            class="footer"
            v-if="!isDisabled"
        >
            <div
                class="btn btn-main"
                @click="onSubmit"
            >
                提交
            </div>
        </div>
        <container ref="container">
            <!--<div class="page-subtitle"><span>成为分销商</span></div>-->
            <x-form>
                <form-item
                    class="required"
                    label="姓名："
                >
                    <input
                        type="text"
                        v-model="formData.distributorName"
                        placeholder="请输入"
                        :disabled="isDisabled"
                    >
                </form-item>
                <form-item
                    class="required"
                    label="性别："
                >
                    <select
                        v-model="formData.distributorSex"
                        :disabled="isDisabled"
                    >
                        <option value="">
                            请选择
                        </option>
                        <option value="1">
                            男
                        </option>
                        <option value="2">
                            女
                        </option>
                    </select>
                </form-item>
                <!--<form-item
                    class="required"
                    label="出生日期："
                >
                    <datetime
                        :min-year="1950"
                        class="datetime"
                        v-model="formData.distributorBirthday"
                        :readonly="isDisabled"
                    >{{formData.distributorBirthday||'请选择出生日期'}}
                    </datetime>
                </form-item>-->
                <form-item
                    class="required"
                    label="手机："
                >
                    <input
                        type="text"
                        v-model="formData.distributorTel"
                        placeholder="请输入"
                        :disabled="isDisabled"
                    >
                </form-item>
                <form-item
                    class="required"
                    label="微信号："
                >
                    <input
                        type="text"
                        v-model="formData.wechatId"
                        placeholder="请输入"
                        :disabled="isDisabled"
                    >
                </form-item>
                <form-item
                    label="上传凭证："
                >
                    <ImgList
                        v-if="isDisabled"
                        :column="3"
                        :data="infoDetail.applyVoucherUrlList"
                    />
                    <Upload
                        v-else
                        :column="3"
                        :max-files="5"
                        v-model="formData.applyVoucher"
                        action="/rpc/comm/rs/attachment/uploadDistributorApplyVoucherImage"
                        :disabled="isDisabled"
                    />
                </form-item>
                <!--<form-item
                    class="required"
                    label="所在区域："
                >
                    <region-picker
                        v-model="formData.distributorRegionId"
                        :disabled="isDisabled"
                    />
                </form-item>
                <form-item
                    class="required"
                    label="家庭地址："
                >
                    <x-input
                        v-model="formData.distributorAddress"
                        placeholder="如街道，楼层，门牌号等"
                        :disabled="isDisabled"
                    />
                </form-item>
                <form-item
                    class="required"
                    label="邮编："
                >
                    <input type="text"
                           v-model="formData.distributorPostcode"
                           placeholder="请输入"
                           :disabled="isDisabled"
                    >
                </form-item>-->
            </x-form>
            <!-- 待审核 -->
            <div
                class="verify tc-blue"
                v-if="infoDetail.approvalStatus === 2"
            >
                <i class="f-icon">&#xf032;</i>
            </div>
            <!-- 通过 -->
            <div
                class="verify tc-main"
                v-else-if="infoDetail.approvalStatus === 1"
            >
                <i class="f-icon">&#xf034;</i>
            </div>
            <!-- 不通过 -->
            <div
                class="verify tc-red"
                v-else-if="infoDetail.approvalStatus === 3"
            >
                <i class="f-icon">&#xf033;</i>
                <div class="ta-c tc-red pa-a fs-normal">
                    {{ infoDetail.approvalRemark }}
                </div>
            </div>
        </container>
        <div
            v-if="infoDetail.approvalStatus === 1"
            class="footer"
        >
            <linker
                replace
                class="btn btn-main"
                to="/distributor/home"
            >
                分销商中心
            </linker>
        </div>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';

export default {
    name: 'DistributorRegister',
    mixins: [pageMixin],
    data() {
        return {
            infoDetail: {},
            formData: {
                empNo: '', // 工卡号
                distributorName: '', // 姓名
                distributorSex: '0', // 姓名
                distributorTel: '', // 电话
                wechatId: '', // 微信号
                distributorEmail: '', // 邮箱地址
                distributorAddress: '', // 邮寄地址
                distributorPostcode: '', // 邮编
                distributorBirthday: '', // 出生日期
                distributorRegionId: '', // 请选择区域
                bankCardNo: '', // 银行卡号
                bankBranchCode: '',
                bankCode: '',
                bankCard: '',
                invitationCode: '',
                applyVoucher: [],
            },
        };
    },
    computed: {
        $$bankBranchCode() {
            return this.$refs.bankBranchCode;
        },
        $$bankCode() {
            return this.$refs.bankCode;
        },
        $$bankCard() {
            return this.$refs.bankCard;
        },
        isDisabled() {
            // 2:待审核;1:通过;3:不通过;
            return this.infoDetail.approvalStatus === 1 || this.infoDetail.approvalStatus === 2;
        },
        invitationCode() {
            return decodeURIComponent(this.$route.query.invitationCode || '');
        },
    },
    methods: {
        getDistributorInfoDetail() {
            this.$api.Buyer.Mb.Distributor.distributorInfoDetail().then(json => {
                const result = json.data.data;
                this.infoDetail = result;
                if (result.approvalStatus === 1) {
                    this.addIdentity(2); // 添加分销商身份
                }
                for (const key in this.formData) {
                    if (this.formData.hasOwnProperty(key)) {
                        if (key === 'applyVoucher') {
                            this.formData[key] = result[key]
                                ? result[key].split(',').reduce((pre, name) => {
                                      pre.push({
                                          address: result.baseUrl + name,
                                          name,
                                      });
                                      return pre;
                                  }, [])
                                : [];
                        } else {
                            this.formData[key] = (result[key] || '') + '';
                        }
                    }
                }
                const bankArr = this.formData.bankCardNo.split('-');
                this.formData.bankBranchCode = bankArr[0] || '';
                this.formData.bankCode = bankArr[1] || '';
                this.formData.bankCard = bankArr[2] || '';
            });
        },
        onSubmit() {
            const bankCardNo = [];
            this.formData.bankBranchCode && bankCardNo.push(this.formData.bankBranchCode);
            this.formData.bankCode && bankCardNo.push(this.formData.bankCode);
            this.formData.bankCard && bankCardNo.push(this.formData.bankCard);
            const data = {
                ...this.formData,
                bankCardNo: bankCardNo.join('-'),
                invitationCode: this.invitationCode,
                applyVoucher: this.formData.applyVoucher.map(item => item.name).join(','),
            };
            this.$api.Buyer.Mb.Distributor.saveDistributorInfoDetail(data).then(json => {
                this.$messageBox.alert(json.data.msg, '温馨提示').then(() => {
                    console.log(1111);
                    window.location.reload();
                    this.$wxBase.closeWindow();
                });
            });
        },
        onInput(event, type) {
            const key = event.key;
            if (isNaN(key) && key !== 'Backspace') return;
            const value = event.target.value;
            switch (type) {
                case 1:
                    if (value.length >= 5 && key !== 'Backspace') {
                        // event.preventDefault();
                        this.$$bankCode.focus();
                    } else if (value.length <= 0) {
                        // 保留
                    }
                    break;
                case 2:
                    if (value.length >= 3 && key !== 'Backspace') {
                        // event.preventDefault();
                        this.$$bankCard.focus();
                    } else if (value.length <= 0 && key === 'Backspace') {
                        this.$$bankBranchCode.focus();
                    }
                    break;
                case 3:
                    if (value.length > 11 && key !== 'Backspace') {
                        event.preventDefault();
                    } else if (value.length <= 0 && key === 'Backspace') {
                        this.$$bankCode.focus();
                    }
                    break;
            }
        },
    },
    activated() {
        this.getDistributorInfoDetail();
    },
};
</script>

<style lang="scss">
.verify {
    text-align: center;
    padding: $padding;
    > .f-icon {
        font-size: 1.4rem;
        line-height: 1;
    }
}
</style>
