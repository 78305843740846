<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page
        class="bc-g1"
        :title="pageData.title"
    >
        <div class="second-header">
            <div>筛选等级:</div>
            <x-select
                v-model="distributorLevelId"
            >
                <option value="">
                    全部
                </option>
                <option
                    v-for="(item) in distributorLevelList"
                    :key="item.id"
                    :value="item.id"
                >
                    {{ item.levelName }}
                </option>
            </x-select>
        </div>
        <container ref="container">
            <div class="tool-bar nma-b bor-b">
                <div
                    v-for="(item,index) in subordinateInfo"
                    :key="index"
                    class="item"
                >
                    <div class="num tc-price">
                        {{ item.buyerNum }}
                    </div>
                    <div class="label">
                        {{ item.name }}
                    </div>
                </div>
            </div>

            <List class="ma-t">
                <ListItem label="直属下级" />
            </List>
            <List class="pa-a">
                <team-item
                    class="ma-bor-b"
                    v-for="(item) in teamList"
                    :key="item.id"
                    :data="item"
                />
            </List>
            <infinite-loading
                ref="infinite"
                :disabled="infiniteDisabled"
                @infinite="onInfinite"
            />
        </container>
    </Page>
</template>

<script>
import { Buyer } from '@/api';
import pageMixin from '@/mixin/layout/page';
import infiniteLoadingMixin from '@/mixin/infiniteLoading';
import { mapState, mapActions } from 'vuex';

export default {
    name: 'TeamList',
    mixins: [pageMixin, infiniteLoadingMixin],
    data() {
        return {
            distributorLevelId: '',
            teamList: [],
            subordinateInfo: [],
        };
    },
    beforeRouteEnter(to, from, next) {
        Buyer.Mb.Distributor.distributorSubordinateInfo().then(json => {
            next(vm => {
                vm.subordinateInfo = json.data.data;
            });
        });
    },
    computed: {
        ...mapState('distributor', ['levelList']),
        distributorLevelList() {
            if (this.levelList === null) {
                this.getLevelList();
                return [];
            }
            return this.levelList;
        },
    },
    watch: {
        distributorLevelId(val, oldVal) {
            if (val === oldVal) return;
            this.infiniteDisabled = true;
            this.pagination = {};
            this.teamList = [];
            this.$nextTick(() => {
                this.infiniteDisabled = false;
            });
        },
    },
    methods: {
        ...mapActions('distributor', ['getLevelList']),
        getListData({ distributorLevelId = this.distributorLevelId, currentPage } = {}) {
            return this.$api.Buyer.Mb.Distributor.distributorSubordinateList({
                distributorLevelId,
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
            }).then(json => {
                const res = json.data.data;
                this.teamList = this.teamList.concat(res);
                return Promise.resolve(json);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.second-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 $padding;
    background-color: #fff;
    > .select {
        flex: 1 1 1%;
        line-height: 0.8rem;
        border: 0;
    }
}
</style>
