<!-- Created by henian.xu on 2017/10/1. -->

<template>
    <Page class="bc-g1">
        <div class="second-header classify">
            <div
                class="classify-list"
                ref="classifyList"
            >
                <!--<div class="right-icon f-icon" v-show="!classifyListSR" @click="onClassifyListSR">&#xf012;</div>-->
                <div
                    :class="['item',{'active':orderStatus === val}]"
                    tag="div"
                    :key="val"
                    :to="`/shelves/shelvesOrderList/${val}`"
                    @click="onClassify(val)"
                    v-for="(val) in orderStatusList"
                    :ref="`classifyItem${val}`"
                >
                    <div class="label">
                        {{ orderStatusTable[val] }}
                    </div>
                </div>
            </div>
        </div>
        <div class="second-header">
            <time-select
                v-model="statTimeData"
                :all-time="allTime"
                @change="onTimeSelectChange"
            />
        </div>
        <container ref="container">
            <div class="order-list ma-t">
                <div
                    class="item"
                    v-for="(item) in orderList"
                    :key="item.id"
                >
                    <div class="header">
                        <div class="label">
                            <div>
                                订单编号：{{ item.orderSn }}
                                <!--<span class="badge badge-red">{{item.shipMethod + '' === '1' ? '送货' : '自取'}}</span>-->
                            </div>
                            <div class="ctrl">
                                <div
                                    class="tc-second"
                                    v-if="item.orderStatus===6 && (item.groupBuyNums - item.joinBuyNums)>0"
                                >
                                    还差
                                    {{ item.groupBuyNums - item.joinBuyNums }} 人
                                </div>
                                <div
                                    class="tc-g6"
                                    v-else
                                >
                                    {{ item.orderStatusName }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="body">
                        <div class="goods-cart-list">
                            <div
                                :key="item2.id"
                                class="item bc-g1"
                                v-for="(item2) in item.orderDetailList"
                            >
                                <div
                                    class="img-obj"
                                    :style="`background-image: url(${$options.filters.imgCropping(item2.specImageUrl,{width:200})});`"
                                />
                                <div class="inner">
                                    <dl>
                                        <dt class="label">
                                            {{ item2.goodsName }}
                                        </dt>
                                    </dl>
                                    <div
                                        class="sku"
                                        v-if="item2.specDesc"
                                    >
                                        {{ item2.specDesc }}
                                    </div>
                                    <dl>
                                        <dd class="price">
                                            {{ item2.price | price }}
                                        </dd>
                                        <dd>x {{ item2.quantity }}</dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        <div
                            class="list nma-b"
                            v-if="+item.orderStatus === 5"
                        >
                            <div class="item">
                                <div class="label">
                                    买家留言：<span>{{ item.buyerMsg?item.buyerMsg:'无' }}</span>
                                </div>
                            </div>
                            <div class="item">
                                <div class="label">
                                    取消人
                                </div>
                                <div class="details">
                                    {{ item.cancelOperator }}
                                </div>
                            </div>
                            <div class="item">
                                <div class="label">
                                    取消原因
                                </div>
                                <div class="details">
                                    {{ item.cancelDesc }}
                                </div>
                            </div>
                            <div class="item">
                                <div class="label">
                                    取消时间
                                </div>
                                <div class="details">
                                    {{ item.updateTime | date }}
                                </div>
                            </div>
                        </div>
                        <div class="fn-flex row jc-sb ai-c pa-a bor-t">
                            <div>
                                <div>{{ item.orderTime|date }}</div>
                            </div>
                            <div class="ta-r">
                                <div>分享佣金：<span class="price">{{ item.commisionAmount }}</span></div>
                                <div class="tc-g6 fs-small">
                                    商品金额合计：<span class="price">{{ item.goodsAmount }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <infinite-loading
                @infinite="onInfinite"
                ref="infinite"
            >
                <div
                    class="ta-c"
                    slot="loading"
                >
                    <div
                        class="nprogress"
                        v-if="!pagination.currentPage"
                    >
                        <div class="spinner-icon">
                            <div class="monkey" />
                            <div class="cloud01" />
                            <div class="cloud02" />
                            <div class="shadow" />
                        </div>
                        <div>加载中...</div>
                    </div>
                    <div v-else>
                        加载中...
                    </div>
                </div>
                <div slot="no-result">
                    <div class="goods-nothing-icon">
                        <i class="f-icon">&#xf03e;</i>
                    </div>
                    <div class="goods-nothing">
                        您还没有相关的订单
                    </div>
                </div>
            </infinite-loading>
        </container>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';

export default {
    name: 'OrderList',
    mixins: [pageMixin],
    data() {
        return {
            pagination: {},
            statTimeData: {},
            orderList: [],
            orderStatusList: ['10', '100', '99'],
            orderStatusTable: {
                // 全部 -1、待付款 0 、待拼团 6、待发货 1、待收货 2、已完成 4、已取消 5
                '-1': '全部',
                '10': '进行中',
                '100': '已完成',
                '99': '已取消',
            },
        };
    },
    computed: {
        orderStatus: {
            get() {
                return this.$route.params.orderStatus;
            },
            set(val) {
                this.$router.replace(`/distributor/orderList/${val}`);
            },
        },
        _classifyList() {
            return this.$refs.classifyList;
        },
        _currClassifyItem() {
            return this.$refs[`classifyItem${this.orderStatus}`];
        },
        infiniteState() {
            const infinite = this.$refs['infinite'];
            return infinite ? infinite.infiniteState : null;
        },
        allTime() {
            return !!+this.$route.query.allTime;
        },
    },
    methods: {
        onClassify(val) {
            if (this.orderStatus === val) return;
            this.orderStatus = val;
            this.orderList = [];
            this.pagination = {};
            this._classifyList.scrollLeft =
                this._currClassifyItem[0].offsetLeft -
                (document.documentElement.clientWidth - this._currClassifyItem[0].clientWidth) / 2;
        },
        getDistributorOrderList() {
            return this.$api.Buyer.Mb.Distributor.distributorOrderList({
                settlementStatus: this.orderStatus,
                statBeginDate: this.statTimeData.beginDate,
                statEndDate: this.statTimeData.endDate,
            }).then(json => {
                const result = json.data;
                this.orderList = this.orderList.concat(result.data);
                return Promise.resolve(json);
            });
        },
        onTimeSelectChange(timeData) {
            const infinite = this.$refs['infinite'];
            const infiniteState = infinite ? infinite.infiniteState : null;
            if (!infiniteState) return;
            console.log(timeData);
            this.statTimeData = { ...timeData };
            this.infiniteState.reset();
            this.orderList = [];
        },
        // 无限加载
        onInfinite($state) {
            this.getDistributorOrderList().then(json => {
                const result = json.data;
                this.pagination = result.pagination;
                const { currentPage, totalRows, totalPages } = this.pagination;
                if (!totalRows || currentPage === totalPages) {
                    if (totalRows) {
                        $state.loaded();
                    }
                    $state.complete();
                } else {
                    $state.loaded();
                }
            });
        },
    },
    created() {},
    mounted() {
        // this.getDistributorOrderList();
    },
};
</script>

<style lang="scss">
.second-header {
    > .time-select {
        background-color: #fff;
        line-height: 0.9rem;
    }
}

.classify-list {
    //position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    margin-bottom: $margin-big;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    height: 100%;

    .right-icon {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0;
        right: 0;
        width: 0.8rem;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.6);
    }

    .item {
        /*width: 20%;*/
        height: 100%;
        padding: $padding 1em;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        > .label {
            line-height: 1;
        }
        &.active {
            color: $color-main;
            border-bottom: 5px solid $color-main;
        }
    }
}

.second-header.classify {
    overflow: hidden;
    .classify-list {
        justify-content: space-between;
        white-space: inherit;
        align-items: flex-start;
        height: calc(100% + 0.4rem);
        > .item {
            flex: 1 1 auto;
            height: 0.8rem;
            padding-left: 0;
            padding-right: 0;
            white-space: nowrap;
            text-align: center;
        }
    }
}

.second-header {
    &.classify {
        height: 0.8rem;
    }
}

.order-list {
    //border-top: 1px solid $color-border;
    //border-bottom: 1px solid $color-border;
    > .item {
        background-color: #fff;
        margin-bottom: $margin-big;

        > .header {
            padding: $padding;
            border-bottom: 1px solid $color-border;
            > .label {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                // font-size: .26rem;
            }
            > .order-sn {
                color: $gray6;
            }
        }
        > .body {
        }
        > .footer {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            padding: $padding;
            border-top: 1px solid $color-border;
            > .btn + .btn {
                margin-left: $margin;
            }
        }
    }
}

.goods-cart-list {
    display: block;
    background-color: #fff;
    //border-top: 1px solid $color-border;
    //border-bottom: 1px solid $color-border;

    > .item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding;
        //border-top: 1px solid $color-border;

        &:first-child {
            border-top: 0;
        }

        > .checkbox {
            margin-right: $margin;
        }
        .img-obj {
            position: relative;
            min-width: 1.88rem;
            width: 1.88rem;
            height: 1.88rem;
            background-size: cover;
            background-position: center;
            > .inner {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.5);
                color: #fff;
                font-size: 0.3rem;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &.sell-out {
                    background: rgba(0, 0, 0, 0.5) url('~@/assets/images/comm/icon01.png') center no-repeat;
                    background-size: auto 70%;
                }
                &.sold-out {
                    background: rgba(0, 0, 0, 0.5) url('~@/assets/images/comm/icon02.png') center no-repeat;
                    background-size: auto 70%;
                }
            }
        }
        .inner {
            min-width: inherit;
            flex-grow: 1;
            height: 1.88rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            padding: 0 0 0 $padding;

            > dl {
                display: flex;
                width: 100%;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                > .label {
                    @include text-line(2);
                    padding-right: $padding;
                    font-size: 0.26rem;
                    line-height: 1.5;
                    min-height: 0.26rem * 1.5 * 2;
                }
                > .del {
                    align-self: flex-start;
                    > .btn {
                        white-space: nowrap;
                    }
                }
                > dd {
                    white-space: nowrap;
                    text-align: right;
                }
            }
            > .sku {
                border: 1px solid $color-main;
                padding: 0 $padding-small;
                border-radius: $padding-small;
                color: $color-main;
            }
        }
        > .body {
            flex: 1 1 1%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background-color: #fff;
            padding: $padding;
            box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
            border-radius: 0.05rem;
        }
    }
}
</style>
