<!-- Created by henian.xu on 2019/1/2. -->

<template>
    <div class="percentage-item">
        <div
            v-if="data.distributorLevelName"
            class="header"
        >
            <div class="label">
                {{ data.distributorLevelName }}
            </div>
        </div>
        <div class="body">
            <div class="tool-bar nma-b bor-b">
                <div
                    v-for="(item,index) in data.list"
                    :key="index"
                    class="item"
                >
                    <div :class="['num tc-price',{price:item.isPrice}]">
                        <template v-if="item.isPrice">
                            {{ item.value | price }}
                        </template>
                        <template v-else>
                            {{ item.value }}
                        </template>
                    </div>
                    <div class="label">
                        {{ item.name }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PercentageItem',
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default() {
                return {};
            },
        },
    },
};
</script>

<style lang="scss">
.percentage-item {
    background-color: #fff;
    + .percentage-item {
        margin-top: $margin;
    }
    > .header {
        padding: $padding;
        border-bottom: 1px solid $color-border;
    }
}
</style>
