<!-- Created by henian.xu on 2019/1/3. -->

<template>
    <div class="user-box-distributor">
        <div class="inner">
            <div
                class="avatar"
                :style="`background-image: url('${$options.filters.imgCropping(data.avatar||$defaultAvatar,{width:200})}');`"
            >
                <!--<div
                    class="level"
                    :style="`background-image: url('${data.levelIconUrl}');`"/>-->
            </div>
            <div class="body">
                <div class="info">
                    <i class="f-icon">&#xf003;</i>&nbsp;
                    <span class="nickname">{{ data.distributorName }}</span>
                    <Linker
                        :to="`tel://${data.distributorTel}`"
                        class="mobile ma-l badge badge-g8 fs-small"
                    >
                        <!--<i class="f-icon">&#xf026;</i>--> {{ data.distributorTel }}
                    </Linker>
                </div>
                <div class="info fx-jc-sb">
                    <div class="level">
                        等级: {{ data.levelName }}
                    </div>
                    <Button
                        v-if="$globalVar.shopData.distributorRegisterMode === 2 && data.upgradeDistributorLevel"
                        class="ma-l"
                        theme="second"
                        size="mini"
                        radius="all"
                        to="/distributor/cardList"
                        label="权益升级"
                    />
                </div>
                <template v-if="$globalVar.shopData.distributorRegisterMode === 2">
                    <div
                        v-if="data.dueTime"
                        class="fs-small"
                    >
                        {{ data.dueTime | moment('YYYY-MM-DD') }} 尊享到期
                    </div>
                    <div class="recommend-code">
                        邀请码: <span>{{ data.invitationCode }}</span>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserBoxDistributor',
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default() {
                return {};
            },
        },
    },
};
</script>

<style lang="scss">
.user-box-distributor {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: $padding-big * 2;
    min-height: 2.4rem;
    //background-image: linear-gradient(mix($color-main, $color-yellow, 60%), $color-main);
    background-image: linear-gradient(160deg, #fff -500%, $color-second 70%);
    color: #fff;

    > .inner {
        flex: 1 1 1%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        > .avatar {
            flex: 0 0 auto;
            position: relative;
            width: 1.6rem;
            height: 1.6rem;
            border-radius: 50%;
            background: transparent center no-repeat;
            background-size: cover;
            border: 0.04rem solid #fff;

            > .level {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 50%;
                background: transparent center no-repeat;
                background-size: cover;
            }
        }
        > .body {
            flex: 1 1 1%;
            margin-left: $margin;
            > .info {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                .nickname {
                    font-weight: bold;
                    font-size: 120%;
                    @include text-line(1);
                }
            }

            > .recommend-code {
                display: inline-block;
                background-color: #fff;
                color: $color-main;
                padding: 0 $padding-small;
                //border: 1px solid $color-border;
                border-radius: 0.1rem;
                margin-top: $margin-small;
                font-size: 90%;
            }
        }
    }
}
</style>
