<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <div class="second-header">
            <Tabs
                average
                @tabChange="onTabsChange"
            >
                <TabPanel
                    v-if="$hasPower(['distributorIncomeMode:eachLevelIncome']) && $globalVar.userData.distributionIncomeMode & 4"
                    :active-panel="sign === 'sell'"
                    sign="sell"
                    label="团队收益"
                />
                <TabPanel
                    v-if="$hasPower(['distributorIncomeMode:recommendIncome']) && $globalVar.shopData.distributorRegisterMode === 2"
                    :active-panel="sign === 'recommend'"
                    sign="recommend"
                    label="推荐提成"
                />
                <TabPanel
                    v-if="$hasPower(['distributorIncomeMode:shareIncome']) && $globalVar.userData.distributionIncomeMode & 1"
                    :active-panel="sign === 'share'"
                    sign="share"
                    label="分享返现"
                />
                <TabPanel
                    v-if="$hasPower(['distributorIncomeMode:storeEachLevelIncome']) && $globalVar.userData.distributionIncomeMode & 8"
                    :active-panel="sign === 'store'"
                    sign="store"
                    label="门店层级提成"
                />
            </Tabs>
        </div>
        <container ref="container">
            <Panel
                v-for="(item,index) in dataList"
                :key="index"
                :to="`/distributor/percentage/${sign}?month=${formatMonth(item.momthCode)}`"
                class="ma-bor-b"
                :label="item.monthDesc"
            >
                <div
                    class="extra"
                    slot="extra"
                >
                    明细 <div class="f-icon fs-small">
                        &#xf012;
                    </div>
                </div>
                <div class="tool-bar">
                    <div class="item">
                        <div class="num tc-price">
                            {{ item.totalOrderNum }}
                        </div>
                        <div class="label">
                            订单
                        </div>
                    </div>
                    <div
                        v-if="sign === 'sell'"
                        class="item"
                    >
                        <div class="num price">
                            {{ item.totalIncomeAmount }}
                        </div>
                        <div class="label">
                            订单销售金额
                        </div>
                    </div>
                    <div class="item">
                        <div class="num price">
                            {{ item.totalIncomeAmount }}
                        </div>
                        <div class="label">
                            到账提成
                        </div>
                    </div>
                </div>
            </Panel>

            <infinite-loading
                ref="infinite"
                :disabled="infiniteDisabled"
                @infinite="onInfinite"
            />
        </container>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import infiniteLoading from '@/mixin/infiniteLoading';

export default {
    name: 'PerMonth',
    mixins: [pageMixin, infiniteLoading],
    data() {
        return {
            dataList: [],
        };
    },
    computed: {
        sign() {
            return this.$route.params.sign + '';
        },
    },
    methods: {
        getListData({ currentPage, pageSize } = {}) {
            let fn = 'recommendIncomeMonthList';
            let distributionIncomeMode;
            if (this.sign === 'sell') {
                fn = 'orderDistributionMonthList';
                distributionIncomeMode = 4;
            } else if (this.sign === 'share') {
                fn = 'orderDistributionMonthList';
                distributionIncomeMode = 1;
            } else if (this.sign === 'store') {
                fn = 'orderDistributionMonthList';
                distributionIncomeMode = 8;
            }
            return this.$api.Buyer.Mb.Distributor[fn]({
                distributionIncomeMode,
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
                pageSize,
            }).then(json => {
                this.dataList = this.dataList.concat(json.data.data);
                return Promise.resolve(json);
            });
        },
        onTabsChange(item) {
            this.infiniteDisabled = true;
            this.dataList = [];
            this.pagination = {};
            this.$nextTick(() => {
                this.$router.replace(
                    `/distributor/monthList/${item.sign}`,
                    () => {
                        console.log(123);
                    },
                    () => {
                        this.infiniteDisabled = false;
                        console.log(456);
                    },
                );
            });
        },
        formatMonth(val) {
            val += '';
            const valArr = val.split('');
            valArr.splice(4, 0, '-');
            return valArr.join('');
        },
    },
};
</script>

<style lang="scss">
</style>
