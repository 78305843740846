<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <container
            class="category"
            ref="container"
        >
            <GoodsCategory
                v-model="catId"
                :activity-type="+activityType"
                @input="onCategoryInput"
            />
            <div class="goods-wrap">
                <list class="goods">
                    <goods-item
                        v-for="(item) in goodsList"
                        :key="item.id"
                        :data="item"
                        share-earn
                    />
                </list>
                <infinite-loading
                    ref="infinite"
                    :disabled="infiniteDisabled"
                    @infinite="onInfinite"
                />
            </div>
        </container>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import infiniteLoading from '@/mixin/infiniteLoading';
import { mapGetters } from 'vuex';
import GoodsCategory from '@/pages/goods/_GoodsCategory';

export default {
    name: 'GoodsList',
    components: { GoodsCategory },
    mixins: [pageMixin, infiniteLoading],
    data() {
        return {
            goodsList: [],
            catId: +this.$route.params.catId || 0,
            infiniteDisabled: !+this.$route.params.catId,
        };
    },
    computed: {
        ...mapGetters('goods', ['catMap']),
        catId2() {
            return this.$route.params.catId;
        },
        activityType() {
            return this.$route.params.activityType;
        },
        catName() {
            const catMap = this.catMap[this.activityType] || {};
            const mapItem = catMap[this.catId];
            return mapItem ? mapItem.name : '';
        },
    },
    methods: {
        getListData({ catId = this.catId, currentPage, pageSize } = {}) {
            return this.$api.Buyer.Mb.Distributor.distributorGoodsList({
                catId,
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
                pageSize,
            }).then(json => {
                const res = json.data;
                this.goodsList = this.goodsList.concat(res.data);
                return Promise.resolve(json);
            });
        },
        onCategoryInput(val) {
            if (!val) return;
            this.infiniteDisabled = true;
            this.goodsList = [];
            this.pagination = {};
            this.$nextTick(() => {
                this.infiniteDisabled = false;
            });

            this.setShareData({
                title: this.catName,
                desc: this.$globalVar.shopData.name,
                link: `/distributor/goods/list/${this.catId}`,
                successData: {
                    urllinkType: 2,
                    objectId: this.catId,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.category {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    > .goods-wrap {
        flex: 1 1 1%;
        overflow-y: auto;
        overflow-x: hidden;
    }
}
</style>
