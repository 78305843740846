<!-- Created by henian.xu on 2019/6/24. -->

<template>
    <Page>
        <div class="second-header">
            <Tabs
                average
                @tabChange="onTabsChange"
            >
                <TabPanel
                    sign="1"
                    label="日视图"
                />
                <TabPanel
                    sign="2"
                    label="月视图"
                />
            </Tabs>
        </div>
        <Container ref="container">
            <time-select
                class="ma-b"
                v-model="statTimeData"
                :all-time="allTime"
                :format="formatMap[currentSign]"
                @change="onTimeSelectChange"
            />
            <XTable class="pa-a">
                <table>
                    <colgroup>
                        <col width="25%">
                        <col width="25%">
                        <col width="25%">
                        <col width="25%">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>PV</th>
                            <th>UV</th>
                            <th>IP数</th>
                            <th>订单数</th>
                        </tr>
                    </thead>
                    <template v-if="dataList && dataList.length">
                        <tbody
                            v-for="(item,index) in dataList"
                            :key="index"
                        >
                            <tr>
                                <td
                                    class="fw-bold npa-t npa-b"
                                    colspan="4"
                                >
                                    {{ item.orderTime }}
                                </td>
                            </tr>
                            <tr>
                                <td>{{ item.pv }}</td>
                                <td>{{ item.uv }}</td>
                                <td>{{ item.ip }}</td>
                                <td>{{ item.orderNum }}</td>
                            </tr>
                        </tbody>
                    </template>
                    <tbody v-else>
                        <tr>
                            <td
                                class="ta-c"
                                colspan="4"
                            >
                                暂无数据
                            </td>
                        </tr>
                    </tbody>
                </table>
            </XTable>
        </Container>
    </Page>
</template>

<script>
export default {
    name: 'VisitLogDetail',
    data() {
        return {
            statTimeData: {},
            allTime: false,
            formatMap: {
                '1': 'YYYY-MM-DD',
                '2': 'YYYY-MM',
            },
            currentSign: '1',
            dataList: [],
        };
    },
    methods: {
        async getDataList() {
            const { currentSign, statTimeData } = this;
            const res = await this.$api.Buyer.Mb.Distributor.buyerVisitLogDetail({
                type: currentSign,
                statBeginDate: statTimeData.beginDate,
                statEndDate: statTimeData.endDate,
                statBeginDateMonth: statTimeData.beginDate,
                statEndDateMonth: statTimeData.endDate,
            });
            this.dataList = res.data.data || [];
        },
        onTabsChange(item) {
            this.currentSign = item.sign;
            // this.getDataList();
        },
        onTimeSelectChange() {
            this.$nextTick(() => {
                this.getDataList();
            });
        },
    },
};
</script>

<style lang="scss">
</style>
