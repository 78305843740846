<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <container ref="container">
            <div class="money-box">
                <div class="label">
                    账户余额
                </div>
                <div class="money price">
                    {{ accountBalance }}
                </div>
                <Button
                    to="/distributor/withdraw"
                    theme="w"
                    class="tc-main"
                    radius="all"
                    size="small"
                    label="提取记录"
                />
            </div>
            <!--<div class="tool-bar ma-bor-b">
                <div class="item">
                    <div class="num price">{{ distributor.totalIncome + distributor.frozenAmount | price }}</div>
                    <div class="label">本月已提现</div>
                </div>
                <div class="item">
                    <div class="num price">{{ distributor.totalIncome | price }}</div>
                    <div class="label">今日已提现</div>
                </div>
            </div>-->

            <div class="tool-bar ma-bor-b">
                <div class="item">
                    <div class="num price">
                        {{ distributor.totalIncome + distributor.frozenAmount | price }}
                    </div>
                    <div class="label">
                        预期提成
                    </div>
                </div>
                <div class="item">
                    <div class="num price">
                        {{ distributor.totalIncome | price }}
                    </div>
                    <div class="label">
                        到账提成
                    </div>
                </div>
            </div>
            <Panel
                v-if="$hasPower(['distributorIncomeMode:eachLevelIncome']) && $globalVar.userData.distributionIncomeMode & 4"
                to="/distributor/monthList/sell"
                class="ma-bor-b"
                label="团队收益"
            >
                <div
                    class="extra"
                    slot="extra"
                >
                    查看详情 <div class="f-icon fs-small">
                        &#xf012;
                    </div>
                </div>
                <div class="tool-bar">
                    <div class="item">
                        <div class="num price">
                            {{ (eachLevelIncome.waitCompleteCommisionAmount || 0) + (eachLevelIncome.completeCommisionAmount || 0) | price }}
                        </div>
                        <div class="label">
                            预期提成
                        </div>
                    </div>
                    <div class="item">
                        <div class="num price">
                            {{ eachLevelIncome.completeCommisionAmount || 0 | price }}
                        </div>
                        <div class="label">
                            到账提成
                        </div>
                    </div>
                </div>
            </Panel>
            <Panel
                v-if="$hasPower(['distributorIncomeMode:recommendIncome']) && $globalVar.shopData.distributorRegisterMode ===2"
                to="/distributor/monthList/recommend"
                class="ma-bor-b"
                label="推荐提成"
            >
                <div
                    class="extra"
                    slot="extra"
                >
                    查看详情 <div class="f-icon fs-small">
                        &#xf012;
                    </div>
                </div>
                <div class="tool-bar">
                    <div class="item">
                        <div class="num price">
                            {{ recommendIncome.totalIncomeAmount || 0 | price }}
                        </div>
                        <div class="label">
                            预期提成
                        </div>
                    </div>
                    <div class="item">
                        <div class="num price">
                            {{ recommendIncome.totalIncomeAmount || 0 | price }}
                        </div>
                        <div class="label">
                            到账提成
                        </div>
                    </div>
                </div>
            </Panel>
            <Panel
                v-if="$hasPower(['distributorIncomeMode:shareIncome']) && $globalVar.userData.distributionIncomeMode & 1"
                to="/distributor/monthList/share"
                class="ma-bor-b"
                label="分享返现"
            >
                <div
                    class="extra"
                    slot="extra"
                >
                    查看详情 <div class="f-icon fs-small">
                        &#xf012;
                    </div>
                </div>
                <div class="tool-bar">
                    <div class="item">
                        <div class="num price">
                            {{ (shareIncome.waitCompleteCommisionAmount || 0) + (shareIncome.completeCommisionAmount || 0) | price }}
                        </div>
                        <div class="label">
                            预期提成
                        </div>
                    </div>
                    <div class="item">
                        <div class="num price">
                            {{ shareIncome.completeCommisionAmount || 0 | price }}
                        </div>
                        <div class="label">
                            到账提成
                        </div>
                    </div>
                </div>
            </Panel>
            <Panel
                v-if="$hasPower(['distributorIncomeMode:storeEachLevelIncome']) && $globalVar.userData.distributionIncomeMode & 8"
                to="/distributor/monthList/store"
                label="门店层级提成"
            >
                <div
                    class="extra"
                    slot="extra"
                >
                    查看详情 <div class="f-icon fs-small">
                        &#xf012;
                    </div>
                </div>
                <div class="tool-bar">
                    <div class="item">
                        <div class="num price">
                            {{ (storeEachLevelIncome.waitCompleteCommisionAmount || 0) + (storeEachLevelIncome.completeCommisionAmount || 0) | price }}
                        </div>
                        <div class="label">
                            预期提成
                        </div>
                    </div>
                    <div class="item">
                        <div class="num price">
                            {{ storeEachLevelIncome.completeCommisionAmount || 0 | price }}
                        </div>
                        <div class="label">
                            到账提成
                        </div>
                    </div>
                </div>
            </Panel>
        </container>
        <PageBar>
            <WithdrawPopup
                :max-amount="+accountBalance"
                @success="onWithdrawSuccess"
            >
                <Button
                    theme="main"
                    label="提现"
                />
            </WithdrawPopup>
        </PageBar>
    </Page>
</template>

<script>
import { Buyer } from '@/api/index';
import pageMixin from '@/mixin/layout/page';

export default {
    name: 'Collect',
    mixins: [pageMixin],
    data() {
        return {
            distributor: {},
            eachLevelIncome: {}, // 团队收益
            recommendIncome: {}, // 推荐提成
            shareIncome: {}, // 分享返现
            storeEachLevelIncome: {}, // 门店层级提成
        };
    },
    beforeRouteEnter(to, from, next) {
        Promise.all([Buyer.Mb.Distributor.distributorDetail(), Buyer.Mb.Distributor.distributorDistributionDetail()])
            .then(jsons => {
                const distributor = jsons[0].data.data;
                const income = jsons[1].data.data;
                next(vm => {
                    vm.distributor = distributor;
                    vm.eachLevelIncome = income.eachLevelIncome || {};
                    vm.recommendIncome = income.recommendIncome || {};
                    vm.shareIncome = income.shareIncome || {};
                    vm.storeEachLevelIncome = income.storeEachLevelIncome || {};
                });
            })
            .catch(() => {
                next();
            });
    },
    computed: {
        accountBalance() {
            return this.$options.filters.price(
                this.distributor.totalIncome - this.distributor.cashedAmount - this.distributor.cashingAmount,
            );
        },
    },
    methods: {
        // 已弃用
        onWithdraw() {
            console.log('onWithdraw');
            const self = this;
            const { accountBalance } = this;
            this.$messageBox
                .prompt(`<div class="ma-b">账户余额 <span class="price">${accountBalance}</span></div>`, '提至零钱', {
                    desc: `<div class="tc-red fs-small ma-t">所提交的金额将实时划入您的零钱中，提取到零钱中的金额可用于商品购买。</div>`,
                    placeholder: '请输入要提取到零钱的金额',
                    inputAutoSize: {
                        minRows: 1,
                        maxRows: 1,
                    },
                    validCallback(action, val) {
                        return new Promise((resolve, reject) => {
                            if (action !== 'confirm') return resolve(true);
                            if (isNaN(val)) {
                                reject({
                                    msg: `<div class="tc-red">请输入数值</div>`,
                                });
                                return;
                            }
                            self.$api.Buyer.Mb.Distributor.distributorWithdraw({ withdrawAmount: val })
                                .then(json => {
                                    const res = json.data;
                                    if (res.success) {
                                        resolve(true);
                                        self.distributor.cashedAmount = +self.distributor.cashedAmount + +val;
                                        this.$messageBox.tips(res.msg);
                                        return;
                                    }
                                    const obj = {
                                        msg: `<div class="tc-red">${res.msg}</div>`,
                                    };
                                    reject(obj);
                                })
                                .catch(reject);
                        });
                    },
                })
                .then(action => {
                    console.log(action);
                });
        },
        onWithdrawSuccess(val) {
            this.distributor.cashedAmount = +this.distributor.cashedAmount + +val;
        },
    },
};
</script>

<style lang="scss" scoped>
.money-box {
    //background-image: linear-gradient(mix($color-main, $color-yellow, 60%), $color-main);
    background-image: linear-gradient(160deg, #fff -500%, $color-main 70%);
    > .money,
    > .label {
        color: #fff;
    }
}
</style>
