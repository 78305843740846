<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <div class="second-header">
            <time-select
                v-model="statTimeData"
                :all-time="allTime"
                @change="onTimeSelectChange"
            />
        </div>
        <container ref="container">
            <div
                v-for="item in dataList"
                :key="item.id"
                class="team-log-item"
            >
                <div class="time">
                    {{ item.sendTime | moment('YYYY-MM-DD HH:mm:ss') }}
                </div>
                <div class="desc">
                    {{ item.messageContent }}
                </div>
            </div>

            <infinite-loading
                ref="infinite"
                :disabled="infiniteDisabled"
                @infinite="onInfinite"
            />
        </container>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import infiniteLoading from '@/mixin/infiniteLoading';

export default {
    name: 'TeamLog',
    mixins: [pageMixin, infiniteLoading],
    data() {
        return {
            dataList: [],
            statTimeData: {},
        };
    },
    computed: {
        allTime() {
            return !!+this.$route.query.allTime;
        },
    },
    methods: {
        getListData({
            beginTime = this.statTimeData.beginDate,
            endTime = this.statTimeData.endDate,
            currentPage,
            pageSize,
        } = {}) {
            return this.$api.Comm.Ms.UserMessage.userMessageList({
                beginTime,
                endTime,
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
                pageSize,
            }).then(json => {
                this.dataList = this.dataList.concat(json.data.data);
                return Promise.resolve(json);
            });
        },

        onTimeSelectChange(timeData) {
            console.log(timeData);
            this.infiniteDisabled = true;
            this.dataList = [];
            this.pagination = {};
            this.$nextTick(() => {
                this.infiniteDisabled = false;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.second-header {
    > .time-select {
        background-color: #fff;
        line-height: 0.9rem;
    }
}
</style>
<style lang="scss">
.team-log-item {
    padding: $padding;
    background-color: #fff;

    + .team-log-item {
        margin-top: $margin;
    }
    > .time {
        font-weight: bold;
    }
    > .desc {
        color: $gray6;
        word-break: break-all;
        white-space: pre-line;
    }
}
</style>
