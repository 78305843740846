<!-- Created by henian.xu on 2019/7/4. -->

<template>
    <Page>
        <Container ref="container">
            <i
                class="f-icon tc-g3"
                style="font-size: 2.5rem;"
            >&#xf006;</i>
            <div class="ta-c pa-a ma-t">
                缴纳 <span class="price">{{ storeInfo.openStoreFee }}</span> 开通店铺, 并赚取分销佣金！
            </div>
        </Container>
        <PageBar>
            <Button
                label="缴费"
                theme="main"
                @click="()=>{onSubmit()}"
            />
            <pay-popup
                v-if="orderId"
                v-show="false"
                ref="payPopup"
                has-back
                :order-ids="orderId"
                :module="5"
            />
        </PageBar>
    </Page>
</template>

<script>
import { Buyer } from '@/api';

export default {
    name: 'RegisterStore',
    data() {
        return {
            storeInfo: {},
            orderId: 0,
        };
    },
    beforeRouteEnter(to, from, next) {
        Buyer.Mb.BuyerOpenStoreOrder.getOpenStoreInfo()
            .then(json => {
                const res = json.data;
                console.log(res);
                next(vm => {
                    vm.storeInfo = res.data;
                });
            })
            .catch(() => next());
    },
    methods: {
        async onSubmit() {
            if (!this.orderId) {
                const json = await Buyer.Mb.BuyerOpenStoreOrder.createBuyerOpenStoreOrder();
                const res = json.data.data;
                console.log(res);
                if (res.orderStatus !== 10) {
                    this.$router.replace('/buyer/home');
                    return;
                }
                this.orderId = res.id;
            }
            this.$nextTick(() => {
                this.$refs['payPopup'].open();
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $gray6;
}
</style>
