<!-- Created by henian.xu on 2017/10/12 0012. -->

<template>
    <div>
        <div class="user-box-ranking">
            <div class="info">
                <div
                    class="img-obj"
                    :style="`background-image: url(${userData.userAvatar});`"
                >
                    <i
                        class="f-icon"
                        v-if="userData.userAvatar === ''"
                    >&#xf02c;</i>
                    <div
                        class="icon3"
                        :style="userData.levelIconUrl?`background-image: url('${userData.levelIconUrl}');`:''"
                    />
                </div>
                <div class="user-name">
                    {{ userData.nickName }}
                </div>
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserBoxRanking',
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default() {
                return null;
            },
        },
    },
    computed: {
        userData() {
            return {
                ...this.$globalVar.userData,
                ...this.data,
            };
        },
    },
};
</script>

<style lang="scss">
.user-box-header-img-obj {
    transition: height 1.5s;
    height: 0;
    line-height: 0;
    overflow: hidden;
    > img {
        width: 100%;
        height: auto;
    }
    &.active {
        height: 280/750 * 100vw;
    }
}

.user-box-ranking {
    position: relative;
    width: 100%;
    min-height: (240vw / 750 * 100);
    /*background: url("~@/assets/images/img/user-bg.jpg") center no-repeat;*/
    background-color: #fff;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $color-border;

    > .info {
        /*width: 80%;*/
        // background-color: rgba(0, 0, 0, .5);
        /*color: #fff;*/
        padding: $padding;
        /*display: flex;*/
        /*flex-direction: row;*/
        /*justify-content: flex-start;*/
        /*align-items: center;*/
        text-align: center;

        > .img-obj {
            position: relative;
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 21vw;
            height: 21vw;
            background-size: cover;
            background-position: center center;
            background-color: $gray1;
            /*box-shadow: 0 0 20px 10px rgba(255, 255, 255, .5);*/
            border-radius: 50%;
            /*border: 8px solid #1d1b00;*/
            //margin-right: $margin-big;

            > .f-icon {
                font-size: 12vw;
                color: $gray5;
            }

            > .icon3 {
                position: absolute;
                bottom: 0.05rem;
                right: 0.05rem;
                width: 0.4rem;
                height: 0.4rem;
                border-radius: 50%;
                overflow: hidden;
                background: transparent url('~@/assets/images/comm/icon03.png') center no-repeat;
                background-size: 100% 100%;
            }
        }
        > .user-name {
            font-size: 0.3rem;
        }
    }

    .level {
        .level-icon {
            width: 1em;
            height: 1em;
            vertical-align: middle;
        }
    }
}
</style>
